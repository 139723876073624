import request from '@/api/request.js'

//分页查询Bom信息
export function getBomPage(params) {
  return request({
    url: `bom/page`,
    method: 'get',
    params
  })
}

//查询Bom明细
export function getBomDetail(params) {
  return request({
    url: `bom/details`,
    method: 'get',
    params
  })
}

// 改变可见状态
export function setBomEnable(id) {
  return request({
    url: `bom/detail/${id}`,
    method: 'put'
  })
}

// 处理完成
export function setBomFinish(id) {
  return request({
    url: `bom/${id}`,
    method: 'put'
  })
}

// bom同步
export function bomSync(params) {
  return request({
    url: `bom/sync`,
    method: 'post',
    params
  })
}

//----------------------------------------
// 存货档案分类查询
export function listInventoryClass() {
  return request({
    url: `inventorySync/classes`,
    method: 'get'
  })
}
// 存货档案查询
export function listInventory(params) {
  return request({
    url: `inventorySync/page`,
    method: 'get',
    params
  })
}
// 改变可见状态
export function setInventoryShow(code) {
  return request({
	url: `inventorySync/showOrNot/${code}`,
    method: 'put',
  })
}
// 处理完成
export function setInventoryFinish() {
  return request({
    url: `inventorySync/finished`,
    method: 'put'
  })
}


//----------------------------------------
// 查询序列号生产子件
export function listAssetBom(params) {
  return request({
    url: `bom/assetBom`,
    method: 'get',
	params
  })
}
export function pageSnByKey(params) {
  return request({
    url: `asset/data/pageSnByKey`,
    method: 'get',
	params
  })
}
